import styled from 'styled-components/macro'

export const HomeStyled = styled.div`
  position: relative;
  text-align: center;
  padding-bottom: 200px;

  .my-masonry-grid {
    margin: 100px 30px 0 0;
    width: 1100px;

    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    /* margin-left: -30px; */
    width: auto;

    img {
      margin-top: 30px;
      width: 100%;
    }
  }

  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  .right {
    position: absolute;
    top: 20px;
    right: 20px;
    max-width: calc(50vw - 40px);
  }

  .left {
    position: absolute;
    top: 20px;
    left: 20px;
    max-width: calc(50vw - 40px);
  }

  .main {
    position: relative;
    margin: auto;
    padding-top: 130px;
    display: block;
    max-width: calc(100vw - 40px);
  }
`

export const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-gap: 100px;
  margin: 130px auto;
  width: 1100px;

  img {
    width: 100%;
  }

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 300px);
    grid-gap: 100px;
    width: 700px;
  }

  @media (max-width: 800px) {
    grid-template-columns: auto;
    grid-gap: 0;
    width: 100%;

    img {
      width: 100%;
      max-width: calc(100vw - 40px);
      margin-bottom: 20px;
    }
  }
`
