export const backgroundColorDark = '#000'
export const backgroundColorLight = '#f8f8f8'
export const backgroundColorDeep = '#fff'
export const shadowColor = '#00000040'
export const borderColor = '#E0E1E4'
export const textColor = '#fff'
export const buttonColor = "#0B2641";
export const subTextColor = '#FFF'
export const descTextColor = '#586A7D'
export const backgroundTextColor = '#3F4468'
export const bgTextColor = '#FFF'
export const placeholderColor = '#989FBA'
export const primaryColor = '#9966FF'
export const secondaryColor = '#1CEFE4'
export const tertiaryColor = '#f1946a'
export const upColor = '#1EA672'
export const downColor = '#F90021'
export const headerColor = '#10122399'
export const inactiveColor = '#242649'
export const counterColor = '#6D728F'
export const transparent = '#00000000'
